import Layout from '../../components/layout/layout';
import { TextBlock } from '../../components/shared/text-block.jsx';

import { PRIMARY_TEXT_COLOR, PRIMARY_TITLE_COLOR, TITLE_FONT } from '../../constants';
import React from 'react';
import { Container } from 'reactstrap';
import { Wrapper } from '../../components/shared/wrapper';
import { Fullscreen } from '../../components/fullscreen/fullscreen';
import { Background } from '../../components/background/background';
import styled from 'styled-components';

import cover from '../../../static/images/services/frontend.png';
import { TechnologyBadges } from '../../components/services/technology-badges';

const TitleContainer = styled(Container)`
    z-index: 1;
`;

const Title = styled.div`
    font-family: ${TITLE_FONT};
    font-size: 140px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.94;
    letter-spacing: -9.7px;
    color: ${PRIMARY_TEXT_COLOR};
    text-shadow: 1px 1px 2px ${PRIMARY_TITLE_COLOR};

    @media (max-width: 992px) {
        font-size: 100px;
        letter-spacing: -6px;
    }

    @media (max-width: 768px) {
        font-size: 59px;
        line-height: 3;
        letter-spacing: -3.1px;
    }
`;

export default () => (
    <Layout>
        <Fullscreen height="84vh" className="d-flex justify-content-center flex-column position-relative">
            <Background bg={cover} design={'cover'} index={1} />
            <TitleContainer>
                <Title>Frontend development.</Title>
            </TitleContainer>
        </Fullscreen>
        <Wrapper color={PRIMARY_TEXT_COLOR}>
            <Container>
                <TechnologyBadges title="Technology." technologies={['Node.js', 'Angular 7', 'React']} />
                <TextBlock title="About.">
                    <p>
                        We use the latest technology and processes and we are experts in a number of web technologies. We use the latest
                        technology and processes and we are experts in a number of web technologies.We use the latest technology and
                        processes and we are experts in a number of web technologies.
                    </p>
                    <p>
                        We use the latest technology and processes and we are experts in a number of web technologies. We use the latest
                        technology and processes and we are experts in a number of web technologies.We use the latest technology and
                        processes and we are experts in a number of web technologies.
                    </p>
                    <p>
                        We use the latest technology and processes and we are experts in a number of web technologies. We use the latest
                        technology and processes and we are experts in a number of web technologies.We use the latest technology and
                        processes and we are experts in a number of web technologies.
                    </p>
                </TextBlock>
            </Container>
        </Wrapper>
    </Layout>
);
