import { Col, Row } from 'reactstrap';
import React from 'react';
import styled from 'styled-components';
import { TITLE_FONT } from '../../constants';
import { OverlinedSubTitle } from '../shared';

const SpacedRow = styled(Row)`
    margin-bottom: 50px;
    margin-top: 30px;

    span {
        font-family: ${TITLE_FONT};
        margin-left: 5px;
        margin-right: 5px;
        font-size: 20px;
        font-weight: normal;
        padding: 10px 50px;
    }
`;

export const TechnologyBadges = ({ title, technologies, txtcolor }) => {
    return (
        <SpacedRow>
            <Col xs={9} md={3} xl={3}>
                <OverlinedSubTitle txtcolor={txtcolor}>{title}</OverlinedSubTitle>
            </Col>
            <Col xl={{ offset: 1, size: 8 }}>
                {technologies.map(image => {
                    return <span className="badge badge-pill badge-primary">{image}</span>;
                })}
            </Col>
        </SpacedRow>
    );
};
